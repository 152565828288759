<template>
  <v-app v-if="signedIn">
    <pwa-prompt/>

    <v-snackbar v-model="snack.show" :color="snack.color" location="top">{{ snack.text }}</v-snackbar>
    
    <v-app-bar color="primary" :extension-height="extHeight">
      <v-toolbar-title class="pointer" @click="$router.push('/')">
        <img v-if="config.logo" :src="'/' + config.logo" class="app-bar-logo">
        <span v-else>{{ appName }}</span>
      </v-toolbar-title>
      <v-spacer/>
      <div id="appbar"/>
      <user-avatar :user="user" color="white" to="/account" class="mx-4"/>
      <template #extension>
        <div id="appbarext" v-mutate.immediate="onAppBarExtMutated" class="v-tabs--grow"/>
      </template>
    </v-app-bar>

    <v-main :style="mainStyle">
      <info-message/>
      <unpaid-message v-if="hasUnpaid"/>
      <router-view v-if="extReady"/>
    </v-main>

    <v-bottom-navigation grow color="primary">
      <v-btn to="/" icon><v-icon>mdi-weight-lifter</v-icon></v-btn>
      <v-btn to="/progress" icon><v-icon>mdi-progress-check</v-icon></v-btn>
      <v-btn to="/chat" icon>
        <v-badge v-if="user.chatUnreadUser" :content="user.chatUnreadUser" color="secondary">
          <v-icon>mdi-chat</v-icon>
        </v-badge>
        <v-icon v-else>mdi-chat</v-icon>
      </v-btn>
      <v-btn to="/more" icon><v-icon>mdi-menu</v-icon></v-btn>
    </v-bottom-navigation>
  </v-app>

  <v-app v-else-if="signedIn === false">
    <pwa-prompt/>

    <v-app-bar color="primary">
      <v-spacer/>
      <v-toolbar-title class="text-center">
        <img v-if="config.logo" :src="'/' + config.logo" class="app-bar-logo">
        <span v-else>{{ appName }}</span>
      </v-toolbar-title>
      <v-spacer/>
    </v-app-bar>

    <v-main class="d-flex align-center justify-center">
      <sign-up-in class="ma-5" style="width: 400px;"/>
    </v-main>
  </v-app>
</template>

<script setup>
import PwaPrompt from '@bulp/trainup-shared/components/PwaPrompt.vue'
import UserAvatar from '@bulp/trainup-shared/components/UserAvatar.vue'
import config from '@/config.js'
import { user } from '@/firebase'
import { useTheme } from 'vuetify'
import { useSnack } from '@/composables/snack.js'

const InfoMessage = defineAsyncComponent(() => import('@/components/InfoMessage.vue'))
const UnpaidMessage = defineAsyncComponent(() => import('@/components/UnpaidMessage.vue'))
const SignUpIn = defineAsyncComponent(() => import('@/components/SignUpIn.vue'))

const appName = config.appName
document.title = appName
document.querySelector('meta[name="description"]').setAttribute('content', config.appDesc)

const extReady = ref(false)
const extHeight = ref(0)

const signedIn = computed(() => user.value ? true : user.value)
const hasUnpaid = computed(() => user.value?._unpaid)

const theme = useTheme()
watch(() => user.value?.theme, userTheme => {
  theme.global.name.value = userTheme
})

const mainStyle = computed(() => {
  if (theme.global.current.value.bgImg) {
    return 'background: url(\'/' + theme.global.current.value.bgImg + '\')'
  }
})

const { snack } = useSnack()

function onAppBarExtMutated() {
  extReady.value = true
  extHeight.value = document.querySelector('#appbarext').clientHeight
}
</script>